import React from "react"
import BoxAd from "../crumbs/boxAd"

export default function LayoutList({ articles, category }) {
    return (
        <>            
            { articles[0] }
            {
              <BoxAd
                ad={ <iframe src="https://mindenpercben.hu/r/1131289.html" width="336" height="320" frameborder="0" scrolling="no"></iframe> }
              />
            }
            { articles[1] }
            { 
				<BoxAd
                ad={ <div data-gz-block="17f029ff-a000-419d-88fd-926b9e8b1133"></div> }
				/>
			}
            { articles[2] }
            {
              <BoxAd
                ad={ <iframe src="https://mindenpercben.hu/r/1131289.html" width="336" height="320" frameborder="0" scrolling="no"></iframe> }
              />
		  	}
            { articles[3] }
			{
              <BoxAd
                ad={ <iframe src="https://mindenpercben.hu/r/1213599.html" width="336" height="500" frameborder="0" scrolling="no"></iframe> }
              />
		  	}
            { articles[4] }
            {
              <BoxAd
                ad={ <iframe src="https://mindenpercben.hu/r/1213600.html" width="336" height="850" frameborder="0" scrolling="no"></iframe> }
              />
            }
            { articles[5] }            
            { articles[6] }
            {
              <BoxAd
                ad={ <iframe src="https://mindenpercben.hu/r/netex275.html" width="336" height="280" frameborder="0" scrolling="no"></iframe> }
              />
            }
            { articles[7] }
            { articles[8] }
            {
              <BoxAd
                ad={ <iframe src="https://mindenpercben.hu/r/netex323.html" width="336" height="280" frameborder="0" scrolling="no"></iframe> }
              />
            }
            { articles[9] }
            { articles[10] }
            { articles[11] }
            { articles[12] }
            { articles[13] }
            { articles[14] }
            { articles[15] }
            { articles[16] }
            { articles[17] }
            { articles[18] }
            { articles[19] }
            { articles[20] }
            { articles[21] }
            { articles[22] }
            { articles[23] }
            { articles[24] }
            {
              <BoxAd
                ad={ <iframe src="https://mindenpercben.hu/r/1213607.html" width="336" height="480" frameborder="0" scrolling="no"></iframe> }
              />
            }
        </>
    )
}