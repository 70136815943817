import React from "react"
import { Link } from "gatsby"

export default function PaginationButton({ prev, next }) {
    return (
        <>
            <div>
                <center>
                    <ul className="pagination">
                        { !prev && next && <li><Link to={next} className="btn btn btn-primary">További híreink »</Link></li> }
                        { !next && prev && <li><Link to={prev} className="btn btn btn-primary">« Előző</Link></li> }
                        { next && prev && <>
                            <li><a href={prev} className="btn btn btn-primary">« Előző</a></li>
                            <li><a href={next} className="btn btn btn-primary" style={{ marginLeft: `10px` }}>Következő »</a></li>
                        </> }
                    </ul>
                </center>
            </div>
        </>
    )
}